<template>
  <!-- Modal for english language -->
  <q-dialog v-model="modal" ref="infoModal" persistent v-if="language =='en'">
    <q-card class="info">

      <q-scroll-area
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      style="height: 70vh; min-width: 70vw;"
      >

      <q-card-section class="row items-center q-pb-none">
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 title">Struggles for global justice in the Mediterranean.</div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 subtitle">Map of different actors within the Mediterranean area involved in the struggles for global justice in its several dimensions and fields.</div>
      </q-card-section>

      <q-card-section>
        <div class="row">
          <div class="content">
            The <a href="https://www.youthact.cat/" target="_blank" style="text-decoration: none">YouthAct!</a> project is aimed to <b>promote and connect youth struggles for global justice worldwide</b>. Under its umbrella emerges this tool, an online map driven by a leading group of activists and organizations from various countries and territories across the Mediterranean region (like Palestine, Sahara, Catalonia or Belgium, among others). Since its inception, this map has been thought of -and worked as- a <b>collective tool that might help to make visible and to connect a wide range of entities, organizations and different kinds of actors</b> involved in the struggles for the defense of human rights and people’s rights in the region. It consists of a <b>dynamic, accessible and constantly growing and updated database, where anyone can browse and find several different kinds of actors who take part in the myriad of struggles that exist in the region</b>. The map becomes then an open door to get to know them, establish contact with them and build stronger networks fighting for global justice.
            <br><br>
            The map, which distinguishes between different <b>types of actors</b> (NGOs, networks, public institutions, social movements, etc) and different <b>kinds of struggles</b> (feminism, climate justice, human rights, etc) allows you to combine these two categories (like filters) in order to <b>navigate the multiplicity of actors involved in the struggles taking place in the region</b>. Furthermore, while doing it you will be able to learn about -by clicking on them- who they are, what they do, where to find them, how to contact them, where to follow them, etc.
            <br><br>
            This project will be <b>improved and expanded over time by the leading group and is open to any interested actor</b> (collective, entity, organization, etc.) <b>which can easily participate and become visible on the map</b>. If you are interested in taking part in this project with your organization, <b>you can do so by sending an email to <a href="mailto:global@scicat.org" target="_blank" style="text-decoration:none">global@scicat.org</a> and/or by filling the following <a href="https://forms.gle/3tZvotSyXJBnzJQk6" target="_blank" style="text-decoration:none">form</a></b>. Have in mind that before uploading any of your info to the map, we will contact you to confirm your request*.
          </div>
          <div class="content-small">-- <br>* All data you provide will always be managed in accordance with the General Data Protection Regulation (UE) 2016/679 and only those you want will be made visible. They may be included, modified or withdrawn/deleted at any time and SCI Catalunya takes responsibility in safeguarding them, aware that they may be sensitive and compromised data. Except by legal obligation, these data will not be given to third parts.</div>
          <div class="text-h6 subtitle">How to consult the map?</div>
        </div>

        <div class="row">
          <div class="content"><br>Som of the functionalities that the user will find are:</div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box">Filter by entity category</div>
            <br>
            <div class="content-help-box">User can select the entity type to query. The classification is the following one:</div>
            <br>
            <ul>
              <li v-for="type in orgTypes" :key="type.acronym">
                <div class="button">
                  <i v-if="type.icon == 'heart'"><font-awesome-icon :icon="['fal', 'hand-holding-heart']" /></i>
                  <i v-if="type.icon == 'landmark'"><font-awesome-icon :icon="['fal', 'landmark']" /></i>
                  <i v-if="type.icon == 'network'"><font-awesome-icon :icon="['fal', 'chart-network']" /></i>
                  <i v-if="type.icon == 'graduation'"><font-awesome-icon :icon="['fal', 'graduation-cap']" /></i>
                  <i v-if="type.icon == 'users'"><font-awesome-icon :icon="['fal', 'users']" /></i>
                </div>
                <div class="text">
                  <div class="code">{{ type.acronym }}</div>
                  <div v-if="type.icon == 'users'" class="label">Social movements, grassroots organizations</div>
                  <div v-if="type.icon != 'users'" class="label">{{ type.name }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box" style="margin-top:-40px">Zoom to map</div>
            <br>
            <div class="content-help-box">To zoom the map, the user must use the following functions: </div>
            <br>
            <div class="wrapper content-help-box">

              <div class="square"><span class="zoom-icon">+</span></div>
              <span>
                <b style="margin-left:30px;">ZOOM IN</b>
              </span>
              <br>
            </div>
            <br>
            <div class="wrapper content-help-box">
              <div class="square"><span class="zoom-icon">-</span></div>
              <span>
                <b style="margin-left:30px;">ZOOM OUT</b>
              </span>
              <br>
            </div>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box">Filter by topic</div>
            <br>
            <div class="content-help-box">User can filter according the topic to consult.</div>
            <br>
            <div class="wrapper content-help-box">
              <i class="fas fa-toggle-on fa-2x" style="color:#E6EF00; margin-left:10px;"></i>
              <span>
                <b>ON</b>
              </span>
              <br>
            </div>
            <div class="wrapper content-help-box">
              <i class="fas fa-toggle-off fa-2x" style="color:#E6EF00; margin-left:10px;"></i>
              <span>
                <b>OFF</b>
              </span>
              <br>
            </div>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box">Obtain information about a place</div>
            <br>
            <div class="content-help-box">To obtain more information about an organization you have to click on the icon. A popup will then open.</div>

            <div class="title-help-box">
              <span class="fa-stack fa-2x">
                <i class="fas fa-map-marker fa-stack-2x" style="color:#E6EF00"></i>
                <i class="fa fa-hand-holding-heart fa-stack-1x fa-inverse icon-marker" style="color:black"></i>
              </span>
            </div>
          </div>
        </div>

      </q-card-section>
      </q-scroll-area>
    </q-card>
  </q-dialog>

  <!-- Modal for catalan language -->
  <q-dialog v-model="modal" ref="infoModal" persistent v-if="language =='ca'">
    <q-card class="info">

      <q-scroll-area
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      style="height: 70vh; min-width: 70vw;"
      >

      <q-card-section class="row items-center q-pb-none">
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 title">Lluites per la justícia global a la Mediterrània</div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 subtitle">Mapa de diferents actors a la Mediterrània involucrats en les lluites per la justícia global en les seves diverses dimensions i camps.</div>
      </q-card-section>

      <q-card-section>
        <div class="row">
          <div class="content">
            El projecte <a href="https://www.youthact.cat/" target="_blank" style="text-decoration:none">YouthAct!</a> té com a objectiu <b>promoure i connectar les lluites juvenils per la justícia global a tot el món</b>. Sota el seu paraigües sorgeix aquest eina, un mapa en línia impulsat per un grup motor d'activistes i organitzacions de diversos països i territoris de la regió mediterrània (com Palestina, Sàhara, Catalunya o Bèlgica, entre d'altres). Des dels seus inicis, aquest mapa ha estat pensat -i treballat- com una <b>eina col·lectiva que pot ajudar a visibilitzar i connectar un ampli ventall d'entitats, organitzacions i actors diversos</b> implicats en les lluites per la defensa dels drets humans i drets de les persones a la regió. Es tracta d'una <b>base de dades dinàmica, accessible i en constant creixement i actualització, on qualsevol persona pot navegar i trobar diferents tipus d'actors que participen en la infinitat de lluites que hi ha a la regió</b>. El mapa esdevé aleshores una porta oberta per conèixer-los, establir contacte amb ells i construir xarxes més fortes que lluiten per la justícia global.
            <br><br>
            El mapa, que distingeix diferents <b>tipus d'actors</b> (ONG, xarxes, Institucions Públiques, moviments socials, etc.) i diferents <b>àmbits de lluita</b> (feminisme, justícia climàtica, drets humans, etc.) permet combinar aquestes dues categories (com a filtres) per <b>navegar a través de la multiplicitat d'actors implicats en les lluites que tenen lloc a la regió</b>. A més, al fer-ho podreu conèixer -fent clic sobre ells- qui són, què fan, on trobar-los, com contactar-los, on seguir-los, etc.
            <br><br>
            Aquest projecte serà <b>millorat i ampliat amb el temps pel grup motor i està obert a qualsevol actor interessat</b> (col·lectiu, entitat, organització, etc.) <b>que pugui participar fàcilment i fer-se visible a través d'aquest mapa</b>. Si estàs interessat en formar part d’aquest projecte amb la teva organització, <b>podeu fer-ho enviant un correu electrònic a <a href="mailto:global@sicat.org" target="_blank" style="text-decoration:none">global@scicat.org</a> i/o omplint el següent <a href="https://forms.gle/3tZvotSyXJBnzJQk6" target="_blank" style="text-decoration:none">formulari</a></b>. Tingues en compte que abans de pujar qualsevol de la teva informació al mapa, ens posarem en contacte amb tu per confirmar la teva sol·licitud*.
          </div>
          <div class="content-small">-- <br>* Totes les dades que ens facilitis seran gestionades sempre d'acord amb el Reglament General de Protecció de Dades (UE) 2016/679 i només es faran visibles les que vulguis. Es poden incloure, modificar o retirar/suprimir en qualsevol moment i SCI Catalunya es responsabilitza de salvaguardar-los, conscient que poden ser dades sensibles i compromeses. Excepte per obligació legal, aquestes dades no seran cedides a tercers.</div>
          <div class="text-h6 subtitle">Com consultar el mapa?</div>
        </div>

        <div class="row">
          <div class="content"><br>Algunes de les funcionalitats que l'usuari trobarà al mapa són:</div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box">Filtrar segons la categoria d'entitat</div>
            <br>
            <div class="content-help-box">L’usuari pot seleccionar el tipus d’entitat que vol consultar. La classifiació és la següent:</div>
            <br>
            <ul>
              <li v-for="type in orgTypes" :key="type.acronym">
                <div class="button">
                  <i v-if="type.icon == 'heart'"><font-awesome-icon :icon="['fal', 'hand-holding-heart']" /></i>
                  <i v-if="type.icon == 'landmark'"><font-awesome-icon :icon="['fal', 'landmark']" /></i>
                  <i v-if="type.icon == 'network'"><font-awesome-icon :icon="['fal', 'chart-network']" /></i>
                  <i v-if="type.icon == 'graduation'"><font-awesome-icon :icon="['fal', 'graduation-cap']" /></i>
                  <i v-if="type.icon == 'users'"><font-awesome-icon :icon="['fal', 'users']" /></i>
                </div>
                <div class="text">
                  <div class="code">{{ type.acronym }}</div>
                  <div v-if="type.icon == 'users'" class="label">Moviments socials, col·lectius de base</div>
                  <div v-if="type.icon != 'users'" class="label">{{ type.name }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box" style="margin-top:-40px">Zoom al mapa</div>
            <br>
            <div class="content-help-box">Per fer zoom al mapa l'usuari haurà d'utilitzar les següents funcions:</div>
            <br>
            <div class="wrapper content-help-box">

              <div class="square"><span class="zoom-icon">+</span></div>
              <span>
                <b style="margin-left:30px;">ACOSTAR-SE</b>
              </span>
              <br>
            </div>
            <br>
            <div class="wrapper content-help-box">
              <div class="square"><span class="zoom-icon">-</span></div>
              <span>
                <b style="margin-left:30px;">ALLUNYAR-SE</b>
              </span>
              <br>
            </div>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box">Filtrar segons el tema</div>
            <br>
            <div class="content-help-box">L’usuari pot filtrar segons el tema que l'interessi consultar.</div>
            <br>
            <div class="wrapper content-help-box">
              <i class="fas fa-toggle-on fa-2x" style="color:#E6EF00; margin-left:10px;"></i>
              <span>
                <b>ON</b>
              </span>
              <br>
            </div>
            <div class="wrapper content-help-box">
              <i class="fas fa-toggle-off fa-2x" style="color:#E6EF00; margin-left:10px;"></i>
              <span>
                <b>OFF</b>
              </span>
              <br>
            </div>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box">Consultar informació d'un lloc</div>
            <br>
            <div class="content-help-box">Per consultar més informació d'una entitat caldrà que seleccioni la icona i seguidament, s'obrirà una finetra nova.</div>

            <div class="title-help-box">
              <span class="fa-stack fa-2x">
                <i class="fas fa-map-marker fa-stack-2x" style="color:#E6EF00"></i>
                <i class="fa fa-hand-holding-heart fa-stack-1x fa-inverse icon-marker" style="color:black"></i>
              </span>
            </div>
          </div>
        </div>

      </q-card-section>
      </q-scroll-area>
    </q-card>
  </q-dialog>


  <!-- Modal for arabic language -->
  <q-dialog v-model="modal" ref="infoModal" persistent v-if="language =='ar'">
    <q-card class="info">

      <q-scroll-area
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      style="height: 70vh; min-width: 70vw;"
      >

      <q-card-section class="row items-center q-pb-none">
          <q-space></q-space>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 title titrtl" dir="rtl">النضال من أجل العدالة العالمية في البحر الأبيض المتوسط</div>
      </q-card-section>

      <q-card-section>
        <div class="text-h6 subtitle" dir="rtl">خريطة لمختلف الجهات الفاعلة المشاركة في النضال من أجل العدالة العالمية، بمختلف أبعادها ومجالاتها، في منطقة البحر الأبيض المتوسط.</div>
      </q-card-section>

      <q-card-section>
        <div class="row">
          <div class="content">
            <p dir="rtl">المشروع <a href="https://www.youthact.cat/" target="_blank" style="text-decoration:none">!YouthAct</a> يهدف إلى <b>تعزيز نضالات الشباب من أجل العدالة العالمية والربط بينها في جميع أنحاء العالم</b>. وتندرج تحت مظلته هذه الأداة، وهي عبارة عن خريطة إلكترونية تقودها مجموعة دافعة من الناشطين والمنظمات من مختلف البلدان والأقاليم في منطقة البحر الأبيض المتوسط (مثل فلسطين والصحراء الكبرى وكاتالونيا وبلجيكا وغيرها). تم تصميم هذه الخريطة - والعمل عليها - منذ البداية، <b> كأداة جماعية يمكنها أن تساعد في إبراز وربط مجموعة واسعة من الكيانات والمنظمات والجهات الفاعلة المتنوعة </b> التي تشارك في النضال من أجل الدفاع عن حقوق الإنسان وحقوق الناس في المنطقة. فهي <b> قاعدة بيانات ديناميكية، متاحة، متنامية، ومحدثة باستمرار حيث يمكن لأي شخص التصفح والعثور على مختلف الجهات الفاعلة التي تشارك  في النضالات التي لا تعد ولا تحصى في المنطقة. </b>تصبح إذاً الخريطة بابًا مفتوحًا للقائهم والتواصل معهم وبناء شبكات أقوى تناضل من أجل العدالة العالمية.</p>
            <p dir="rtl">تسمح الخريطة، التي تميز <b>أنواعًا مختلفة من الجهات الفاعلة</b> (المنظمات غير الحكومية، الشبكات، المؤسسات العامة، الحركات الاجتماعية، إلخ) <b> ومجالات النضال </b> المختلفة (النسوية، العدالة المناخية، حقوق الإنسان، إلخ) بالجمع بين هاتين الفئتين (كعاملي تصفية) <b> للتنقل عبر الجهات الفاعلة العديددة المشاركة في النضالات التي تشهدها المنطقة.  </b>  بالإضافة، من خلال القيام بذلك، ستتمكن من معرفة - من خلال النقر عليهم- من هم، وماذا يفعلون، وأين يمكن العثور عليهم، وكيفية الاتصال بهم، وأين يمكن متابعتهم، إلخ.</p>
            <p dir="rtl">سيتم <b>  تحسين هذا المشروع وتوسيعه مع مرور الوقت من قبل المجموعة الدافعة وهو متوفر لأي جهة فاعلة مهتمة </b> (مجموعة، كيان، منظمة، إلخ) <b> بإمكانها المشاركة بسهولة وإبراز نفسها  من خلال هذه الخريطة. </b> إذا كنت مهتمًا بأن تكون جزءًا من هذا المشروع مع مؤسستك، <b> فيمكنكم القيام بذلك عن طريق إرسال بريد إلكتروني إلى <a href="mailto:global@scicat.org">global@scicat.org</a> و/أو ملء هذا <a href="https://docs.google.com/forms/d/e/1FAIpQLSdMfnnK1MWmXO85pe7X-Fsb8pWOsKhLlOVUaU2n9fRuqDNvBg/viewform" target="_blank" style="text-decoration:none">النموذج</a></b>. يرجى ملاحظة أنه قبل تحميل أي من معلوماتك على الخريطة، سوف نتصل بك لتأكيد طلبك*</p>
          </div>
          <div class="content-small">
            <p dir="rtl">--</p>
            <p dir="rtl">*ستتم دائمًا إدارة جميع البيانات التي تقدمها لنا وفقاً للائحة العامة لحماية البيانات (الاتحاد الأوروبي) 2016/679 ولن تظهر إلا البيانات التي تريدها. ويمكن إدراجها أو تعديلها أو إزالتها/حذفها في أي وقت، وتكون الخدمة المدنية الدولية في كاتالونيا (SCI Catalunya) مسؤولة عن حمايتها، مع العلم أنها يمكن أن تكون بيانات حساسة ومعرضة للإختراق. وباستثناء الالتزام القانوني، لن يتم نقل هذه البيانات إلى أطراف ثالثة.</p> </div>
          <div class="text-h6 subtitle"><p dir="rtl"><b>كيفية استشارة الخريطة؟ </b></p></div>
        </div>

        <div class="row">
          <div class="content"><br><p dir="rtl">بعض الميزات التي سيجدها المستخدم على الخريطة هي:</p> </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box"><p dir="rtl">التصفية حسب فئة الكيان</p></div>
            <br>
            <div class="content-help-box"><p dir="rtl">يمكن للمستخدم تحديد نوع الكيان الذي يريد استشارته. التصنيف هو كما يلي:</p></div>
            <br>
            <ul>
              <li v-for="type in orgTypes" :key="type.acronym">
                <div class="button">
                  <i v-if="type.icon == 'heart'"><font-awesome-icon :icon="['fal', 'hand-holding-heart']" /></i>
                  <i v-if="type.icon == 'landmark'"><font-awesome-icon :icon="['fal', 'landmark']" /></i>
                  <i v-if="type.icon == 'network'"><font-awesome-icon :icon="['fal', 'chart-network']" /></i>
                  <i v-if="type.icon == 'graduation'"><font-awesome-icon :icon="['fal', 'graduation-cap']" /></i>
                  <i v-if="type.icon == 'users'"><font-awesome-icon :icon="['fal', 'users']" /></i>
                </div>
                <div class="text">
                  <div class="code">{{ type.acronym }}</div>
                  <div v-if="type.icon == 'users'" class="label">Social movements, grassroots organizations</div>
                  <div v-if="type.icon != 'users'" class="label">{{ type.name }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box" style="margin-top:-40px"><p dir="rtl">تكبير الخريطة</p></div>
            <br>
            <div class="content-help-box"><p dir="rtl">لتكبير الخريطة، يجب على المستخدم إستعمال الوظائف التالية:</p> </div>
            <br>
            <div class="wrapper content-help-box">

              <div class="square"><span class="zoom-icon">+</span></div>
              <span>
                <b style="margin-left:30px;">ZOOM IN</b>
              </span>
              <br>
            </div>
            <br>
            <div class="wrapper content-help-box">
              <div class="square"><span class="zoom-icon">-</span></div>
              <span>
                <b style="margin-left:30px;">ZOOM OUT</b>
              </span>
              <br>
            </div>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box"><p dir="rtl">تصفية حسب الموضوع</p> </div>
            <br>
            <div class="content-help-box"><p dir="rtl">يمكن للمستخدم التصفية وفقًا للموضوع الذي يهتم بالاستشارة فيه.</p> </div>
            <br>
            <div class="wrapper content-help-box">
              <i class="fas fa-toggle-on fa-2x" style="color:#E6EF00; margin-left:10px;"></i>
              <span>
                <b>ON</b>
              </span>
              <br>
            </div>
            <div class="wrapper content-help-box">
              <i class="fas fa-toggle-off fa-2x" style="color:#E6EF00; margin-left:10px;"></i>
              <span>
                <b>OFF</b>
              </span>
              <br>
            </div>
          </div>
        </div>

        <div class="row help-col">
          <div class="content_help">
            <div class="title-help-box"><p dir="rtl">استشارة المعلومات حول موقع ما</p> </div>
            <br>
            <div class="content-help-box"><p dir="rtl">للحصول على مزيد من المعلومات حول أحد الكيانات، حدد الرمز وفي ذلك الحين سيتم فتح نافذة جديدة.</p> </div>

            <div class="title-help-box">
              <span class="fa-stack fa-2x">
                <i class="fas fa-map-marker fa-stack-2x" style="color:#E6EF00"></i>
                <i class="fa fa-hand-holding-heart fa-stack-1x fa-inverse icon-marker" style="color:black"></i>
              </span>
            </div>
          </div>
        </div>

      </q-card-section>
      </q-scroll-area>
    </q-card>
  </q-dialog>


</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data () {
    return {
      language: this.getLang(),
      modal: true,
      thumbStyle: {
        right: '2px',
        borderRadius: '5px',
        backgroundColor: 'white',
        width: '12px',
        opacity:1
      },
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: 'white',
        width: '12px',
        opacity: 0.7
      },
    }
  },
  components:{
    FontAwesomeIcon
  },
  computed:{
    orgTypes() {
      return this.$store.getters.orgTypes;
    }
  },
  methods:{
    showModal(){
      this.$refs.infoModal.show();
    },
    getLang(){
      this.$store.dispatch("getCookie");
      return this.$store.getters.lang;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
li {
  width: 70px;
  height: 200px;
  margin-left: 47px;
  cursor: pointer;
}
li>div.button {
  width: 70px;
  height: 70px;
  background-color: #E6EF00;
  color: #141414;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

li>div.button i {
  font-size: 1.5em;
}
li>div.text {
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  width: 130%;
  transform: translateX(-15%);
  color: white;
}
li>div.text .code {
  font-weight: bold;
}


.wrapper { position: relative; }

.wrapper span {
  padding-left:15px;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.zoom-icon{
  margin-left: -16px !important;
  margin-top: -3px !important;
}

.square {
  padding-left: 9px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  height: 30px;
  width: 30px;
  background-color: #E6EF00;
  font-size: 30px;
  color: #292929;

}

.abreviation{
  padding-left: 10px;
  color: white;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
}

.acronym_name{
  padding-left: 10px;
  color: white;
  font-family: 'Barlow', sans-serif;
}

.icon-marker{
  display: inline-block;
  font-size: 0.8em;
  margin-top: -8px;
  margin-left: 2px;

}

.off,
.on{
  margin-left: 20px;
}

.info{
  background-color: #E6EF00;
  min-width: 70vw;
}
.title{
  margin-top: -20px;
  padding-left:50px;
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 38px;
}

.titrtl{
  padding-right:30px;

}
.subtitle{
  padding-left:50px;
  padding-right: 30px;
  font-family: 'Barlow', sans-serif;
  font-weight: bold;
  font-size: 25px;
}
.content{
  padding-left:50px;
  padding-right: 30px;
  padding-bottom: 50px;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
}
.content-small{
  padding-left:50px;
  padding-right: 30px;
  padding-bottom: 50px;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
}
.help-row{
  padding-left:50px;
  padding-right: 30px;
  background-color: #3d401b;
}
.content_help{
  padding-left:20px;
  padding-right: 30px;
  padding-bottom: 50px;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
}
.help-col{
  margin-left:40px;
  width: 40vw;
  background-color: #3d401b;
}
.title-help-box{
  color: white;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding-top: 15px;
  padding-left: 30px;
}
.content-help-box{
  color: white;
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  padding-left: 30px;
}



@media (max-width: 500px) {
  .help-row,
  .content-small,
  .content,
  .subtitle,
  .title{
    padding-left: 0px;
  }

  .content_help{
    padding-left:0px;
  }

  .title{
    font-size: 28px;
  }

  .subtitle{
    font-size: 20px;
  }

  .help-col{
    margin-left:0px;
    width: 90vw;
  }

  /* increase pannel on mobile devices */
  .info{
    min-width: 98vw;
    min-height: 95vh;
  }
  .q-scrollarea{
    min-height: 95vh;
  }
}

</style>
