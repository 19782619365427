<template>
  <ul>
    <li v-for="type in orgTypes" :key="type.acronym" @click="setActive(type)"
        :class="typeClass(type)">
      <div class="button">
        <i v-if="type.icon == 'heart'"><font-awesome-icon :icon="['fal', 'hand-holding-heart']" /></i>
        <i v-if="type.icon == 'landmark'"><font-awesome-icon :icon="['fal', 'landmark']" /></i>
        <i v-if="type.icon == 'network'"><font-awesome-icon :icon="['fal', 'chart-network']" /></i>
        <i v-if="type.icon == 'graduation'"><font-awesome-icon :icon="['fal', 'graduation-cap']" /></i>
        <i v-if="type.icon == 'users'"><font-awesome-icon :icon="['fal', 'users']" /></i>
      </div>
      <div class="text" :style="textStyle(type)">
        <div class="code">{{ type.acronym }}</div>
        <div class="label">{{ type.name }}</div>
      </div>
    </li>
  </ul>
</template>

<script>

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data(){
    return{
    }
  },
  components:{
    FontAwesomeIcon
  },
  methods: {
    setActive(type) {
      this.$store.dispatch("setSelectedOrgTypes", {"key": type.acronym})
    },
    typeClass(type) {
      if (!type.selected) return 'active';
    },
    textStyle(type) {
      if (type.width) return 'transform: none; width: ' + type.width + 'px;';
    }
  },
  computed: {
    orgTypes() {
      return this.$store.getters.orgTypes;
    }
  }
}
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
li {
  width: 90px;
  height: 185px;
  margin-right: 35px;
  cursor: pointer;
}
li:first-child {

}
li>div.button {
  width: 90px;
  height: 90px;
  background-color: #818735;
  color: #E6EF00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
li.active .button {
  background: #E6EF00;
  color: #292929;
}
li>div.button i {
  font-size: 0.5em;
}
li>div.text {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  width: 130%;
  transform: translateX(-15%)
}
li>div.text .code {
  font-weight: bold;
}

@media (max-width: 525px) {
  li>div.button {
    width: 65px;
    height: 65px;
  }
  li {
    width: 55px;
    height: 200px;
    margin-right: 35px;
    cursor: pointer;
  }
  li>div.text{
    font-size: 11px;
    transform: translateX(-4%);
  }
}
</style>
