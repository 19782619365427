<template>
  <q-layout view="hHh lpr fFf">
      <the-map></the-map>
  </q-layout>
</template>

<script>
import { ref } from 'vue';

export default {
  setup () {
    const leftDrawerOpen = ref(true)
    const rightDrawerOpen = ref(false)

    return {
      leftDrawerOpen,
      toggleLeftDrawer () {
        leftDrawerOpen.value = !leftDrawerOpen.value
      },

      rightDrawerOpen,
      toggleRightDrawer () {
        rightDrawerOpen.value = !rightDrawerOpen.value
      }
    }
  }
}
</script>

<style>
aside.q-drawer {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}
</style>
