import { createApp } from 'vue';
import App from './App.vue';
import store from './store/index.js';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import TheMap from "./components/layout/TheMap.vue";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHandHoldingHeart, faLandmark, faChartNetwork, faGraduationCap, faUsers  } from '@fortawesome/pro-light-svg-icons';
library.add(faLandmark);
library.add(faHandHoldingHeart);
library.add(faChartNetwork);
library.add(faGraduationCap);
library.add(faUsers);


const app = createApp(App).use(Quasar, quasarUserOptions).use(Quasar, quasarUserOptions);

app.use(store);
app.component("the-map", TheMap);


app.mount('#app');
