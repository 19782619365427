<template>
  <q-drawer class="my-panel" show-if-above v-model="visible" side="left" behavior="desktop" bordered :width="panelWidth">
    <div class="left-panel">
      <OrgTypeMenu/>
      <TopicMenu/>
    </div>

    <button @click="togglePanel" :class="buttonIcon"><i :class="buttonIconClasses"></i></button>
  </q-drawer>
</template>

<script>
import OrgTypeMenu from '../OrgTypeMenu.vue';
import TopicMenu from '../TopicMenu.vue';



export default {
  data(){
    return{

    }
  },
  components: { OrgTypeMenu, TopicMenu },
  methods: {
    togglePanel() {
      this.$store.dispatch("togglePanelVisibiliy");
    },
  },
  computed: {
    buttonIcon(){
      var class_list = 'fold';
      if (this.$store.getters.panelIsVisible) class_list = 'unfold';
      return class_list;
    },
    buttonIconClasses() {
      var class_list = 'fas fa-play';
      if (!this.$store.getters.panelIsVisible) class_list += ' unfold';
      return class_list;
    },
    orgTypes() {
      return this.$store.getters.orgTypes;
    },
    visible() {
      return this.$store.getters.panelIsVisible;
    },
    panelWidth() {
      if(window.innerWidth <= 525){
        return window.innerWidth;
      }else{
        return this.$store.getters.panelWidth;
      }
      //return this.$store.getters.panelWidth;
    }
  },
  mounted(){

  }
}
</script>

<style scoped>
button {
  position: absolute;
  right: -40px;
  background: #292929;
  width: 40px;
  height: 80px;
  color: white;
  border: none;
  font-size: 14px;
  top: 50px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
button:hover {
  background: #141414;
  color: #E6EF00;
}
button i {
  transition: 0.3s all ease-in;
  transform: rotate(180deg);
}
button i.unfold {
  transform: rotate(0deg);
  transition: 0.3s all ease-out;
}


.left-panel {
  /*padding: 60px 75px 60px 55px;*/
  padding: 60px 35px 60px 55px;
}



@media screen and (max-width: 525px) {
  .left-panel {
    padding: 60px 50px 60px 55px;
  }

  button:hover {
    color: white;
  }

  button.unfold{
    right: 0px;
    background: #797979;
  }
}



</style>
