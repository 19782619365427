<template>
    <q-select
        filled
        square
        dark
        color="yellow"
        bg-color="black"
        v-model="lang_selector"
        :options="options"
        option-value="id"
        option-label="desc"
        emit-value
        map-options
        @update:model-value="onItemClick()"
     >
     </q-select>
</template>

<script>

export default {
  name: 'SwitchLocale',
  data() {
    // set language according to django_language cookie

   // var language = this.$store.getters.lang;

  //  if(this.$store.getters.lang == 'ca'){
  //     language =  'CA';
  //  }else{
  //     language =  'EN';
  //  }

    let language;

    switch(this.$store.getters.lang){
      case 'ca':
        language = 'CA'
        break;
      case 'ar':
        language = 'AR'
        break;
      default:
        language = 'EN'
        break
    }

    return {
      lang_selector: language,
      options:[
        {
          id: 'ca',
          desc: 'CA'
        },
        {
          id: 'en',
          desc: 'EN'
        },
        {
          id: 'ar',
          desc: 'AR'
        }
      ]
    }
  },
  methods:{
    onItemClick(){
      const csrftoken = this.getCookie('csrftoken');

      const request = new Request(
          'api/set_lenguage/',
          {headers: {'X-CSRFToken': csrftoken}}
      );

      // get selected language on the dropdown menu
      var data = {language: this.lang_selector};

      fetch(request, {
          method: 'POST',
          body: JSON.stringify(data)
      }).then(function() {
          location.reload();
      });

    },
    getCookie(name){
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
    }
  },
  mounted(){

  }
}
</script>

<style>

.bg-black {
  background: #141414 !important;
}

.bg-yellow{
  background: #E6EF00 !important;
}

.text-white{
  background: white !important;
}

.q-field__native{
  font-weight: bold !important;
}

.q-field__append{
  color: white !important;
}


</style>
