<template>
    <div class="container" :class="{ rtlLang : isRtlLang }">
      <Modal ref="infoModal"></Modal>

      <div class="left-panel">
        <left-panel></left-panel>
      </div>

      <q-page-container class="wide">
      <div :class="classes" id="map">
        <l-map
          ref="map"
          v-model="map"
          v-model:zoom="zoom"
          :bounds="bounds"
          :zoom="zoom"
          :max-zoom="19"
          :min-zoom="3"
          :options="{zoomControl: false, attributionControl:false}"
          :useGlobalLeaflet="true"
          @ready="onLeafletReady()"
        >

          <l-tile-layer :attribution="attribution" tileSize="512" :options="{ zoomOffset: -1 }" url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwZXNiYXNlc2lndGUiLCJhIjoiY2s2Y2F4YnB5MDk4ZjNvb21rcWEzMHZ4NCJ9.oVtnggRtmtUL7GBav8Kstg"></l-tile-layer>

          <l-control-attribution position="bottomright"></l-control-attribution>
          <l-control-zoom position="bottomright"></l-control-zoom>
          <l-control position="topright" >
            <SwitchLanguage/>
          </l-control>

          <l-control position="bottomright" >
            <button  class="openInfo" @click="openModal">
            <span>i</span>
            </button>
          </l-control>

          <l-feature-group ref="features">
          <marker-cluster :options="{ showCoverageOnHover: false, chunkedLoading: true }">
            <l-marker
              v-for="(entity, index_entity) in entities"
              :key="entity.id"
              :lat-lng="[entity.location.coordinates[1], entity.location.coordinates[0]]"
            >

              <l-icon
                :icon-size="[30, 30]"
                :icon-anchor="[5,33]"
              >
                <span class="location-marker fa-stack fa-2x">
                  <i class="fas fa-map-marker fa-stack-2x" style="color:#E6EF00" ></i>
                  <i v-if="entity.icon == 'heart'" class="icon-marker fa-stack-1x" style="color:black"><font-awesome-icon :icon="['fal', 'hand-holding-heart']" /></i>
                  <i v-if="entity.icon == 'landmark'" class="icon-marker fa-stack-1x" style="color:black"><font-awesome-icon :icon="['fal', 'landmark']" /></i>
                  <i v-if="entity.icon == 'network'" class="icon-marker fa-stack-1x" style="color:black"><font-awesome-icon :icon="['fal', 'chart-network']" /></i>
                  <i v-if="entity.icon == 'graduation'" class="icon-marker fa-stack-1x" style="color:black"><font-awesome-icon :icon="['fal', 'graduation-cap']" /></i>
                  <i v-if="entity.icon == 'users'" class="icon-marker fa-stack-1x" style="color:black"><font-awesome-icon :icon="['fal', 'users']" /></i>
                </span>
              </l-icon>


              <l-popup>
                <div v-if="entity.images.length > 0">
                  <q-carousel
                    animated
                    v-model="activeSlides[index_entity]"
                    navigation
                    infinite
                    control-color="yellow"
                  >
                    <q-carousel-slide v-for="(img, index) in entity.images" :key="img._id" :name="index" :img-src="image_src(img)" />
                  </q-carousel>
                </div>

                <div class="popup_content">
                <div class="row">
                  <div class="col"></div>
                  <div class="col-10 title_popup">{{ entity.name }}</div>
                  <div class="col"></div>
                </div>
                <div class="row">
                  <div class="col"></div>
                  <div class="col-10 city_region">{{ entity.city }}, {{ entity.region }} | {{ entity.type__abbr }}</div>
                  <div class="col"></div>
                </div>
                <div class="row" v-if="entity.description">
                  <div class="col"></div>
                  <div class="col-10 description" v-if="language !='ar'">{{ entity.description }}</div>
                  <div class="col-10 description" v-if="language =='ar'" dir="rtl">{{ entity.description }}</div>
                  <div class="col"></div>
                </div>

                <div class="row" v-if="entity.email != 'null' || entity.phone != 'null' || entity.main_address">
                  <div class="col"></div>
                  <div v-if="language == 'ca'" class="col-10 contact_title">CONTACTE</div>
                  <div v-if="language == 'en'" class="col-10 contact_title">CONTACT</div>
                  <div v-if="language == 'ar'" class="col-10 contact_title">CONTACT (AR)</div>
                  <div class="col"></div>
                </div>
                <div class="row" v-if="entity.email != 'null'">
                  <div class="col"></div>
                  <div class="col-10 contact_info">{{ entity.email }}</div>
                  <div class="col"></div>
                </div>
                <div class="row" v-if="entity.phone != 'null'">
                  <div class="col"></div>
                  <div class="col-10 contact_info">{{ entity.phone }}</div>
                  <div class="col"></div>
                </div>
                <div class="row" v-if="entity.main_address">
                  <div class="col"></div>
                  <div class="col-10 contact_info">{{ entity.main_address }}</div>
                  <div class="col"></div>
                </div>
                <!--Info about other addresses-->
                <div class="row" v-if="entity.address.length > 0">
                  <div class="col"></div>
                  <div v-if="language == 'ca'" class="col-10 contact_title">Altres seus: </div>
                  <div v-if="language == 'en'" class="col-10 contact_title">Other locations: </div>
                  <div v-if="language == 'ar'" class="col-10 contact_title">Other locations (AR): </div>
                  <div class="col"></div>
                </div>
                <div v-if="entity.address.length > 0">
                  <div class="row" v-for="(other_address, index_address) in entity.address" :key="other_address.id">
                    <div class="col"></div>
                    <div class="col-10 contact_info">{{entity.address[index_address]}}</div>
                    <div class="col"></div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col"></div>
                  <div class="col-10">
                    <span v-if="entity.webs_url" class="socials_icon fa-stack fa-1x"><a href="#" @click="openWeb(entity.webs_url)"><i class="fa fa-circle fa-stack-2x icon-background"></i><i class="fas fa-link fa-stack-1x" style="color:white"></i></a></span>
                    <span v-if="entity.twitter" class="socials_icon fa-stack fa-1x"><a href="#" @click="openWeb(entity.twitter)"><i class="fa fa-circle fa-stack-2x icon-background"></i><i class="fab fa-twitter fa-stack-1x" style="color:white"></i></a></span>
                    <span v-if="entity.facebook" class="socials_icon fa-stack fa-1x"><a href="#" @click="openWeb(entity.facebook)"><i class="fa fa-circle fa-stack-2x icon-background"></i><i class="fab fa-facebook fa-stack-1x" style="color:white"></i></a></span>
                    <span v-if="entity.instagram" class="socials_icon fa-stack fa-1x"><a href="#" @click="openWeb(entity.instagram)"><i class="fa fa-circle fa-stack-2x icon-background"></i><i class="fab fa-instagram fa-stack-1x" style="color:white"></i></a></span>
                    <span v-if="entity.youtube" class="socials_icon fa-stack fa-1x"><a href="#" @click="openWeb(entity.youtube)"><i class="fa fa-circle fa-stack-2x icon-background"></i><i class="fab fa-youtube fa-stack-1x" style="color:white"></i></a></span>
                  </div>
                  <div class="col"></div>
                </div>
                <br><br>
                <div class="row">
                  <div class="col"></div>
                  <div class="col-10 contact_info">
                    <span class="topics_popup"  v-for="topic in entity.topics_name" :key="topic.id">
                      <div v-if="!isOthers(topic)">{{ topic }}</div>
                      <div v-if="isOthers(topic)">{{ topic }}: {{ entity.other_topics }}</div>
                    </span>
                  </div>
                  <div class="col"></div>
                </div>
                <br>
                </div>

              </l-popup>
            </l-marker>
          </marker-cluster>
        </l-feature-group>

        </l-map>
      </div>
      </q-page-container>
    </div>
</template>



<script>
import LeftPanel from './LeftPanel.vue';
import Modal from './Modal.vue';
import SwitchLanguage from './SwitchLanguage.vue'
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  LMap,
  LTileLayer,
  LControlZoom,
  LMarker,
  LPopup,
  LIcon,
  LFeatureGroup,
  LControlAttribution,
  LControl,
} from "@vue-leaflet/vue-leaflet";

import MarkerCluster from "./MarkerCluster.vue";

import { ref } from 'vue';

export default {
  components: {
    LeftPanel,
    Modal,
    SwitchLanguage,
    LMap,
    LTileLayer,
    LControlZoom,
    MarkerCluster,
    LMarker,
    LPopup,
    LIcon,
    LFeatureGroup,
    LControl,
    LControlAttribution,
    FontAwesomeIcon,
  },
  data() {

    const isRtlLang = ( this.getLang() == 'ar' ? true : false )

    console.log({isRtlLang})
    console.log(this.getLang())

    return {
      language: this.getLang(),
      isRtlLang: isRtlLang,
      panelIsVisible: true,
      zoom: 3,
      activeSlides: [],
      slide: ref(0),
      attribution: '&copy; <a target="_blank" href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> <a target="_blank" href="https://www.mapbox.com/map-feedback/">Improve this map</a>',
    }
  },
  computed: {
    classes() {
      var class_list = ["map leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom"];
      if (!this.$store.getters.panelIsVisible) class_list.push("wide");
      return class_list.join(" ");
    },
    orgTypes(){
      return this.$store.getters.orgs;
    },
    entities(){
      return this.$store.getters.entities;
    },
    bounds(){
      var entities = L.featureGroup();
      for(var i=0; i<this.$store.getters.ent_coordinates.length; i++){
        var gj = {
            "type":"Feature",
            "geometry":{
              "type": "Point",
              "coordinates": this.$store.getters.ent_coordinates[i]
            }
        };
        var p = L.geoJSON(gj,{});
        entities.addLayer(p);
      }
      return entities.getBounds();
    }
  },
  methods: {
    getLang(){
      this.$store.dispatch("getCookie");
      return this.$store.getters.lang;
    },
    onLeafletReady() {
      this.map = this.$refs.map.leafletObject;

      this.$store.dispatch("initMap", {"map": this.$refs.map.leafletObject});
    },
    openModal(){
      this.$refs.infoModal.showModal();
    },
    openWeb(event){
      window.open(''+event+'');
    },
    image_src(img){
      return img;
    },
    icon_class(entity){
      var class_style = ["icon-marker "+entity.icon+" fa-stack-1x"];
      return class_style;
    },
    mapObject(){
      this.$store.dispatch('initMap', {"map": this.map});
    },
    paneIsVisible() {
      return this.$store.getters.panelIsVisible;
    },
    t(text) {
      this.$store.dispatch('translate', text);
      return this.$store.state.translated;
    },
    isOthers(topic){
      if(topic == 'Others' || topic == 'Altres' || topic == 'Otros'){
        return true;
      }else{
        return false;
      }
    }
  },
  mounted() {
    this.$store.dispatch("loadData");
    this.$store.dispatch("getCookie");
    this.activeSlides = Array(100000).fill(0);
    /*
    setTimeout(() => {
      this.activeSlides = Array(this.$store.state.entities.length).fill(0);
    }, 400)
    */
  }
}

</script>

<style>
body {
  font-family: 'Barlow', sans-serif;
}
.q-drawer {
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  background: #292929;
  color: white;
  font-size: 64px;
}
div.container {
  display: flex;
}
body:not(.q-body--layout-animate) .q-layout--prevent-focus {
  visibility: visible;
}
.q-page-container.wide {
  /* padding: 0 !important; */
}

#map {
  background: black;
  width: calc(100% - 485px);
  height: 100%;
  position: absolute;
  overflow: hidden;
}
#map.wide {
  width: 100%;
}

/* icons */
.location-marker {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -30px;
}

.icon-marker{
  display: inline-block;
  font-size: 0.8em;
  margin-top: -8px;
  margin-left: 2px;

}

/* overwritte leaflet style */
#app .leaflet-control-zoom{
  border: 0px solid rgba(0,0,0,0.2);
}

#app .leaflet-control-zoom-out,
#app .leaflet-control-zoom-in{
  background-color: #E6EF00;
  border-bottom: 0px solid;
  margin-bottom:4px;
  width: 50px;
  height: 50px;
  line-height: 50px;
}


/* Popups overwritte style */
#app .leaflet-popup{
  margin-bottom: 65px;
}


#app .leaflet-popup-content-wrapper{
  padding: 0px;
  border-radius: 0px;
}

.popup_content{
  overflow: scroll;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scrollbar-color: #e6ef00 #f7fcc9;
  scrollbar-width: auto;
  /*max-height: 550px;*/
  max-height: 70vh;
}


#app .leaflet-popup-content{
  min-width: 450px;
  margin: 0px;
}

#app .leaflet-popup-close-button{
  margin-right: -13px;
  margin-top: -11px;
  color: white;
  background-color: #141414;
  padding: 5px 1px 0px 0px;
  border-radius: 25px;
  width: 25px;
  height: 25px;
}

.leaflet-container a {
    color: black;
}

/* Popups midimapping style */
#app .close_popup{
  cursor: pointer;
  padding-top: 25px;
}

.popup_img{
  max-width: 450px;
  height: 70%;
  width: auto;
}

.rtlLang .left-panel { direction: rtl; }
.rtlLang .left-panel li { margin-left: 35px;margin-right: 0; }
.rtlLang .left-panel li > div.text { transform: translateX(15%); }

.rtlLang .text-h6.title{ padding-right: 30px;}

.rtlLang .popup_content {  text-align: right; direction: rtl; }
.rtlLang .popup_content .city_region { text-align: right; direction: ltr;}
.rtlLang .popup_content .contact_info { text-align: right; direction: ltr;}

.rtlLang .topics_popup{
  float: right;
}

.title_popup{
  font-family: 'Komissar', sans-serif !important;
  font-size: 20px !important;
  font-weight: bold;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  border-bottom: 1px solid #E6EF00;
  text-transform: uppercase;
}

.city_region{
  font-family: 'Barlow', sans-serif !important;
  font-size: 18px !important;
  font-weight: bold;
  padding-top: 15px !important;
  padding-bottom: 25px !important;
  text-transform: uppercase;
}

.description{
  font-family: 'Barlow', sans-serif !important;
  font-size: 18px !important;
  padding-bottom: 25px !important;
}

.contact_title{
  font-family: 'Barlow', sans-serif !important;
  font-size: 18px !important;
  font-weight: bold;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.contact_info{
  font-family: 'Barlow', sans-serif !important;
  font-size: 18px !important;
}

.topics_popup{
  font-family: 'Barlow', sans-serif !important;
  font-weight: bold;
  color: white;
  background-color: #141414;
  padding: 3px 3px 3px 3px !important;
  margin-right: 10px;
  margin-bottom: 9px!important;
  white-space: nowrap;
  float: left;
}

.socials_icon{
  margin-top: 10px;
}

.openInfo{
  background: #E6EF00;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #141414;
  border: none;
  font-size: 30px;
  font-weight: normal;
  top: 50px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

/* overwritte markercluster styles */
.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  font-family: 'Barlow', italic;
  color: white;
  background-color: rgb(25, 50, 82, 0.7);
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
    background-color: rgb(25, 50, 82, 0.3);
}


@media (max-width: 450px){
  #app .leaflet-popup-content{
    min-width: 98vw;
  }
}

</style>
