import { createStore } from 'vuex';


const store = createStore({
  state() {
    return {
      map: null,
      bbox: null,
      panelWidth: 485,
      panelIsVisible: true,
      translations: {
        'Site title': window.gettext('Site title'),
        '+ Info': window.gettext('+ Info')
      },
      translated: null,
      toggle_all_topics: true,
      orgs: [],
      entitiesLayer: null,
      selected_orgs: [],
      selected_topics: [],
      orgTypes: [],
      topicList: [],
      entities: [],
      all_entities: [],
      ent_coordinates: [],
      lang: null,

    }
  },
  getters: {
    map(state){
      return state.map;
    },
    bbox(state){
      return state.bbox;
    },
    orgs(state) {
      return state.orgs;
    },
    panelWidth(state) {
      return state.panelWidth;
    },
    panelIsVisible(state) {
      return state.panelIsVisible;
    },
    orgTypes(state) {
      return state.orgTypes;
    },
    topicList(state) {
      return state.topicList;
    },
    entities(state){
      return state.entities;
    },
    all_entities(state){
      return state.all_entities;
    },
    ent_coordinates(state){
      return state.ent_coordinates;
    },
    lang(state){
      return state.lang;
    }
  },
  mutations: {
    loadData(state){
      const url = "api/start/";
      fetch(url).then(response => {
        return response.json();
      }).then(data => {
        /* load org types to STORE */
        state.orgTypes = data.types;
        /* load topic list to STORE */
        state.topicList = data.topics;
        /* load entitties list to STORE*/
        state.entities = data.entities;
        state.all_entities = data.entities;

        for(var i=0; i<data.entities.length; i++){
          state.ent_coordinates.push(data.entities[i].location.coordinates);
        }
      });
    },
    translated(state, text) {
      state.translated = text;
    },
    togglePanelVisibiliy(state) {
      state.panelIsVisible = !state.panelIsVisible;
      setTimeout(() => {
        state.map.invalidateSize();
      }, 1)
    },
    initMap(state, payload) {
      state.map = payload.map;

      const L = window.L;

      // event on open popup
      state.map.on('popupopen', function(e) {
        popup_map_padding(e);

        if(window.screen.width <= 680){
          document.getElementsByClassName("leaflet-control-zoom")[0].style.visibility = 'hidden';
          document.getElementsByClassName("openInfo")[0].style.visibility = 'hidden';
          document.getElementsByClassName("leaflet-control")[0].style.visibility = 'hidden';
          document.getElementsByClassName("q-drawer-container")[0].querySelector('button').style.visibility = 'hidden';
        }
      });

      state.map.on('popupclose', function(e){
        popup_close_position(e);

        document.getElementsByClassName("leaflet-control-zoom")[0].style.visibility = 'visible';
        document.getElementsByClassName("openInfo")[0].style.visibility = 'visible';
        document.getElementsByClassName("leaflet-control")[0].style.visibility = 'visible';
        document.getElementsByClassName("q-drawer-container")[0].querySelector('button').style.visibility = 'visible';
      });


      // move popup and marker to center of map
      function popup_map_padding(e){
        var layer = e.target;
        var px = state.map.project(layer._popup._latlng);
        px.y -= layer._popup._container.clientHeight/2;
        state.map.panTo(state.map.unproject(px),{animate: true});
      }

      // return map to original positon
      function popup_close_position(e){
        var px = state.map.project(e.target._lastCenter);
        state.map.panTo(state.map.unproject(px),{animate: true});
      }

      // add layer with Palestine name
      L.tileLayer('https://api.mapbox.com/styles/v1/mapesbasesigte/ckwlzkivaa99814oco8wdfn73/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwZXNiYXNlc2lndGUiLCJhIjoiY2s2Y2F4YnB5MDk4ZjNvb21rcWEzMHZ4NCJ9.oVtnggRtmtUL7GBav8Kstg').addTo(state.map);
    },
    setSelectedOrgTypes(state, payload) {

      const org = state.orgTypes.find(o => {
        return o.acronym == payload.key;
      });

      org.selected = !org.selected;

      if(org.selected == true){
        // remove selected entitities from entities array
        state.entities = state.entities.filter(item => item.type__abbr !== org.acronym);
        // add to selected_orgs array
        state.selected_orgs.push(org.acronym);
      }else{
        for(var i=0; i< state.all_entities.length; i++){
          if(state.all_entities[i].type__abbr == org.acronym){ // load entities with selected org acronym
            if(state.selected_topics.length > 0){
              if(state.all_entities[i].topics_id.some(r => state.selected_topics.includes(r))){ // don't load entities if filter is applied
                state.entities.push(state.all_entities[i]);
              }
            }else{
              state.entities.push(state.all_entities[i]);
            }
          }
        }
        // remove from selected_orgs array
        state.selected_orgs = state.selected_orgs.filter(item => item !== org.acronym);
      }

    },
    filterByTopic(state, payload){
      if(payload.selected == true){
        // add selected topics to array
        state.selected_topics.push(payload.pk);

        var entities_selected = [];
        for(var i=0; i < state.all_entities.length; i++){
          if(state.all_entities[i].topics_id.some(r => state.selected_topics.includes(r))){
            if(!state.selected_orgs.find(element => element == state.all_entities[i].type__abbr)){ // load entities if org is not selected
              entities_selected.push(state.all_entities[i]);
            }
          }
        }

        state.entities = entities_selected;

      }else{

        // remove selected topics from array
        state.selected_topics = state.selected_topics.filter(item => item !== payload.pk);

        var entities_unselected = [];

        if(state.selected_topics.length > 0){
          for(var e=0; e < state.all_entities.length; e++){
            if(state.all_entities[e].topics_id.some(r => state.selected_topics.includes(r))){
              if(!state.selected_orgs.find(element => element == state.all_entities[e].type__abbr)){ // load entities if org is not selected
                entities_unselected.push(state.all_entities[e]);
              }
            }
          }
        }else{
          for(var f=0; f < state.all_entities.length; f++){
            if(!state.selected_orgs.find(element => element == state.all_entities[f].type__abbr)){ // load entities if org is not selected
              entities_unselected.push(state.all_entities[f]);
            }
          }
        }

        state.entities = entities_unselected;

      }

    },
    setMapBounds(state,payload){
      payload.map.fitBounds(state.ent_coordinates);
    },
    getCookie(state){
      var name = 'django_language';
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }

      if(cookieValue == null){
        if(navigator.language == 'ca' || navigator.language == 'ca-ES'){
          state.lang = 'ca';
        }else if(navigator.language == 'ar' || navigator.language == 'ar-ES'){
          state.lang = 'ar';

        }else{
          state.lang = 'en';
        }

        //state.lang = navigator.language;
        //return navigator.language;
        return state.lang;
      }else{
        state.lang = cookieValue;
        return cookieValue;
      }

    }

  },
  actions: {
    translate(context, text) {
      const translation = context.state.translations[text];
      context.commit('translated', translation);
      return translation;
    },
    togglePanelVisibiliy(context) {
      context.commit("togglePanelVisibiliy");
    },
    initMap(context, payload) {
      context.commit("initMap", payload);
    },
    setSelectedOrgTypes(context, payload) {
      context.commit("setSelectedOrgTypes", payload);
    },
    filterByTopic(context, payload){
      context.commit("filterByTopic", payload);
    },
    loadData(context){
      context.commit("loadData");
    },
    setMapBounds(context, payload){
      context.commit("setMapBounds", payload);
    },
    getCookie(context){
      context.commit("getCookie");
    }
  }
});

export default store;
