<template>
  <ul class="topic_list">
    <li>
      <div v-if="language =='en'"><b>FILTER BY TOPIC</b></div>
      <div v-if="language =='ca'"><b>SELECCIONA L'ÀMBIT</b></div>
      <div v-if="language =='ar'"><p dir="rtl"><b>التصفية حسب فئة الكيان</b></p></div>
    </li>
    <li v-for="topic in topic_list" :key="topic.label">
      <q-toggle
        v-model="topic.selected"
        color="my-yellow"
        :label="topic.label"
        left-label
        @click="filterByTopic(topic)"
      />
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      allLabel: "All topics",
      language: this.getLang()
    }
  },
  computed: {
    topic_list() {
      return this.$store.getters.topicList;
    }
  },
  methods: {
    filterByTopic(topic){
      this.$store.dispatch("filterByTopic", {"pk": topic.pk, "selected":topic.selected});
    },
    getLang(){
      this.$store.dispatch("getCookie");
      return this.$store.getters.lang;
    }
  },
  mounted(){

  }
}
</script>

<style>
.text-my-yellow {
  color: #E6EF00;
}
.bg-my-yellow {
  background: #E6EF00;
}
body .q-toggle.row.inline {
  display: flex;
}
.q-toggle__label {
  flex-grow: 1;
}
ul.topic_list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 40px;
}
ul.topic_list li.all {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
